import React, { Component } from 'react';

export default class PageNotFound extends Component {
    render() {
        return (
            <div>
                <p>The page was not found</p>
            </div>
        );
    }
}